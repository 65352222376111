import React from 'react';
import PropTypes from "prop-types";
import styles from "./callOutBox.module.scss";
const CallOutBox = ({
  children
}) => {
  if (!children) {
    return null;
  }
  return <span className={styles.box} data-sentry-component="CallOutBox" data-sentry-source-file="index.jsx">
			{children}
		</span>;
};
CallOutBox.propTypes = {
  children: PropTypes.any
};
export default CallOutBox;