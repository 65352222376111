import PropTypes from "prop-types"
import { PortableText } from '@portabletext/react';
import ptComponents from "./pt-components";
import MarkdownParser from "./markdown-parser";

const RichText = ({value}) => {
	if (!value) {return null} //If no text is passed return null.

	//This is a safety measure for if a string is passed into RichText instead of an array of blocks.
	//This allows us to just use this component regardless of what type of field we have in sanity.
	if (typeof(value) === "string") {
		return <MarkdownParser>{value}</MarkdownParser>
	}

	return (
		<PortableText value={value} components={ptComponents} />
	)
}

RichText.propTypes = {
	value: PropTypes.any,
	additionalTypes: PropTypes.object
}

export default RichText;
