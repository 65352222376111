import { useState } from "react";
import PropTypes from "prop-types";
import RichText from "components/rich-text/index.js";
import styles from "./styles/audio-player.module.scss";
import UpChevron from "assets/icons/chevron-up.svg";
import { useTranslations } from "next-intl";
const Transcript = ({
  transcript
}) => {
  const hasTranscript = !!transcript?.length;
  const [transcriptIsOpen, setTranscriptIsOpen] = useState(true);
  const t = useTranslations();
  const handleTranscriptToggle = e => {
    e.preventDefault();
    setTranscriptIsOpen(!transcriptIsOpen);
  };
  return <>
		{hasTranscript && <div>
				<div className={`${styles.transcriptSection} ${transcriptIsOpen ? styles.transcriptIsOpen : ""}`}>
					<div className={styles.transcriptWrapper}>
						<div className={styles.transcript}>
							<RichText value={transcript} tabIndex="0" />
						</div>
					</div>

					<button className={styles.transcriptToggle} onClick={handleTranscriptToggle} style={{
          background: "none",
          border: 0,
          color: "currentColor"
        }}>
						<span className={styles.transcriptToggleIcon}><UpChevron /></span>
						<span className={styles.transcriptToggleText}>{transcriptIsOpen ? t('hideTranscript') : t('viewTranscript')}</span>
					</button>
				</div>
			</div>}
	</>;
};
Transcript.propTypes = {
  transcript: PropTypes.array
};
export default Transcript;