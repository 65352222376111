import PropTypes from "prop-types";
import { PortableText } from "@portabletext/react";
import marks from "./pt-components/marks";
import list from "./pt-components/list";
const RichTextInline = ({
  value
}) => {
  if (!value) {
    return null;
  }
  return <PortableText value={value} components={{
    marks: marks,
    list: list
  }} data-sentry-element="PortableText" data-sentry-component="RichTextInline" data-sentry-source-file="rich-text-inline-only.jsx" />;
};
RichTextInline.propTypes = {
  value: PropTypes.any
};
export default RichTextInline;